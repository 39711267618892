import teamphoto from './teamphoto.jpg';
import southholland from './southholland.jpg';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Image from 'react-bootstrap/Image'


const Home = () => {
    return (
      <>
    <Image src={southholland} style={{ width: '100%'}} alt="logo" />
    <Container className='home'>
     <Row>
     <Col></Col>
     </Row>
     <Row>
     <Col><img src={teamphoto} className="img-fluid" alt="teamphoto" /></Col>
     </Row>
     <Row>
     <Col>Peterborough Saints was founded in 2022 with the vision of becoming a cornerstone of Peterborough's local community and advancing through the English football pyramid. The club's mission is to develop grassroots football in the region, offering opportunities for local talent while creating a family-friendly, inclusive environment.</Col>
     </Row>
    
     <Row>
     <Col>While striving for success on the pitch, the club is equally committed to community engagement. With players from diverse backgrounds and skill levels, Peterborough Saints focuses on improving with each game, while keeping enjoyment and team spirit at the heart of everything. Win or lose, the club supports its players like family, fostering camaraderie and a shared love for football. This community-centered ethos promotes a strong sense of belonging and pride among members and supporters.</Col>
     </Row>
     <Row>
     <Col>Although the club is still in its early stages, its ambitions are clear: to steadily climb the football pyramid and eventually compete at the highest level. This vision is supported by a commitment to sustainable growth, ensuring that as the club progresses, it remains deeply connected to the local community.</Col>
     </Row>


    
     </Container>
     </>
    )
  };
  
  export default Home;
    
import { Container, Row, Col } from "react-bootstrap";
import motm2 from './motm2.jpg'
import motm3 from './motm3.jpg'
import murrowbellresult from './murrow bell result.jpg'
import React from "react"
import stangroundcardearesult from './stangroundcardearesult.jpg'
import southhollandresult from './southhollandresult.jpg'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const Results = () => {
    return (

     
      <Container>
      <h1 className="position-fixed-top-0">Results </h1>
        <Row>
        <Col className='results-photos'>
<img src={murrowbellresult}  alt="murrowbellresult" className="img-fluid" />  
</Col> 
        </Row>
<Row>
 <Col className='results-photos'>
 <img src={stangroundcardearesult}  alt="stangroundcardearesult" className="img-fluid" />  
 </Col>
<Col className='results-photos'>
      <img src={motm2}  alt="motm2" className="img-fluid" />
      </Col>
      </Row>   

      <Row>
 <Col>
 <img src={southhollandresult}  alt="southhollandresult" className="img-fluid" />  
 </Col>
<Col>
      <img src={motm3}  alt="motm3" className="img-fluid" />
      </Col>
      </Row>   

       </Container>

      
      
    
  )
};

export default Results;
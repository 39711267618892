import './App.css';
import Container from 'react-bootstrap/Container';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route} from "react-router-dom";
import Home from "./Home";
import UpcomingFixtures from "./UpcomingFixtures";
import ContactUs from './ContactUs';
import Results from './Results';
import Gallery from './Gallery';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import logo from './saintslogo.png';
import React, {useState} from 'react';
import { Image } from 'react-bootstrap';

import facebook from './facebook.png';
import instagram from './instagram.png';
import tiktok from './ticktock.png';




function App() {
  
  //nav bar settings
  const [expanded, setExpanded] =
  useState(false);
  
  const handleToggle = () => {setExpanded(!expanded);
  } ;

  const handleSelect = () => {
    setExpanded(false);
  };

  return (
    
    <div className="App">
      <header className="App-header">
     
      
      <Navbar fixed="bottom" className="custom-navbar"  expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand as={Link} to="#home" classname='d-flex align-items-center'><img src={logo} className="App-logo-header" alt="logo"     />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
          <Navbar.Collapse id="navbar-nav" className='flex-column align-items-end'>
          <Nav className="me-auto" onSelect={handleSelect}>
            <Nav.Link href="#action/3.1" as={Link} to="/">HOME</Nav.Link>
            <Nav.Link href="#action/3.2" as={Link} to="/upcomingfixtures">UPCOMING FIXTURES</Nav.Link>
         <Nav.Link href="#action/3.3" as={Link} to="/results">RESULTS</Nav.Link> 
            <Nav.Link href="#action/3.4" as={Link} to="/gallery">GALLERY</Nav.Link>
            <Nav.Link href="#action/3.5" as={Link} to="/contactus">CONTACT US</Nav.Link >
          </Nav>
          </Navbar.Collapse>
          </Container>
          </Navbar>


          <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/upcomingfixtures" element={<UpcomingFixtures />} />
          <Route path="/results" element={<Results/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/contactus" element={<ContactUs/>} />
          </Routes>
    
  
     
         

  

      </header>
      <footer  className="App-footer">

<Container>
  <Row >
    <Col >
<a href="https://www.facebook.com/share/JUgBirs5s3MMeryE/?mibextid=WC7FNe">
<Image src={facebook}  alt="facebook" width="100%"></Image>
</a>
</Col>
<Col  >
<a href="https://www.instagram.com/peterboroughsaints_fc/">
<Image src={instagram}  alt="instagram" width="100%"></Image>
</a>
</Col>
<Col  >
<a href="https://www.tiktok.com/@peterboroughsaintsfc?_t=8puK0GJAZNY&_r=1">
<Image src={tiktok}  alt="tiktok" width="100%"></Image>
</a>
</Col>
</Row>
</Container>
      </footer>
     
    </div>
  );
}

export default App;

import { Container } from "react-bootstrap";
import fixtures from './first3fixtures.png'
import fixtures2 from './second3fixtures.jpg'
import React from "react"

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';





const UpcomingFixtures = () => {
          return (
<Container>
   <h1>Upcoming Fixtures</h1>
         <img src={fixtures}  alt="logo" style={{ width: '100%'}} />
         <img src={fixtures2}  alt="logo" style={{ width: '100%'}}/>

          </Container>

       )
          
         
      };
      
      export default UpcomingFixtures;
        
   
import React from "react"
import Container from 'react-bootstrap/Container';
import teamphoto from './teamphoto.jpg'
import keeper from './keeper.jpg';
import phili from './phili.jpg';
import phili2 from './phili2.jpg';
import scuffle from './scuffle.jpg';
import shotongoal from './shot on goal.jpg';
import Carousel from 'react-bootstrap/Carousel';
import training from './training.jpg';
import training2 from './training2.jpg';
import training3 from './training3.jpg';
import training4 from './training4.jpg';
import training5 from './training5.jpg';
import training6 from './training6.jpg';


const Gallery = () => {
    return (
      
      <Container  >
<h1>Gallery</h1>

<h2>Match Day Pics</h2>
<Carousel>

      <Carousel.Item > 
      <img src={teamphoto}  alt="logo" className="img-fluid"  />
        <Carousel.Caption>
         </Carousel.Caption>
      </Carousel.Item>
    <Carousel.Item>
      <img src={shotongoal}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
      </Carousel.Caption>
      </Carousel.Item> 
      <Carousel.Item>
      <img src={phili}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={keeper}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={scuffle}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={phili2}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
    </Carousel>


    <h2>Training</h2>
    <Carousel >
      <Carousel.Item>
      <img src={training}  alt="logo" className="img-fluid"  />
        <Carousel.Caption>
         </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={training2}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
      </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={training3}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={training4}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={training6}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img src={training5}  alt="logo" className="img-fluid" />
        <Carousel.Caption>
       </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  





     </Container>
    )
  };
  
  export default Gallery;